import React, { useState,useRef } from "react";
import { Button, Col, Row, Select, Table,Input,Space } from "antd";
import { DateRangePicker } from "react-date-range";
import axios from "axios";
import constants from "../../constants";
import { useAuth } from "../../contexts/authContext";
import { SearchOutlined } from '@ant-design/icons';
import Loader from "../../util/loader";
import { addDays, differenceInDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main css file for DateRangePicker
import "react-date-range/dist/theme/default.css"; // theme css file
import { render } from "@testing-library/react";
import Highlighter from 'react-highlight-words';
import './CatalysUtmAnalytics.css'
const CatalysUtmAnalytics = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  // const [searchText, setSearchText] = useState("");
  const { currentUser } = useAuth();
  const [utm, setUtm] = useState(undefined);
  const [dateState, setDateState] = useState([
    {
      startDate: new Date(new Date().setHours(0, 0, 0)),
      endDate: new Date(new Date().setHours(0, 0, 0)),
      key: "selection",
    },
  ]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString()?.toLowerCase()?.includes(value?.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text?.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const validateDateRange = () => {
    const startDate = dateState[0].startDate;
    const endDate = dateState[0].endDate;
    const diffDays = differenceInDays(endDate, startDate);

    if (diffDays > 30) {
      setError("The date range cannot exceed 30 days.");
      return false;
    }
    return true;
  };

  const fetchData = async () => {
    setError("");
    if (!validateDateRange()) {
      return;
    }

    let startDate = dateState[0].startDate;
    let endDate = dateState[0].endDate;
    let sd = new Date(startDate.getTime()).toISOString();
    let ed = addDays(new Date(endDate.setHours(0, 0, 0)), 1).toISOString();

    if (!utm) {
      setError("UTM tag is required.");
      return;
    }

    try {
      setLoading(true);
      const url = `${constants.NEW_DOMAIN}/api/v1/admin/catalysAnalytics?utmTag=${utm}&startDate=${sd}&endDate=${ed}`;
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
        },
      });
      setData(res.data?.data || []);
      if(res.data.data?.length === 0){
        setError("No Data Found")
      }
    } catch (error) {
      setError("Failed to fetch data. Please try again later.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Filter data based on the _id search text
  const filteredData = data?.filter((item) =>
    item._id.toLowerCase().includes(searchText.toLowerCase())
  );

  // Define columns for the Ant Design Table
  const columns = [
    {
      title: `${utm}`,
      dataIndex: "_id",
      key: "_id",
      render: (text) => <a>{text  ? text : "EMPTY"}</a>,
      ...getColumnSearchProps('_id'),
    },
    {
      title: "COLD",
      dataIndex: "COLD",
      key: "COLD",
      render: (text) => <a>{text ? text : 0}</a>,
      sorter: (a, b) => (a.COLD || 0) - (b.COLD || 0), // treat missing data as 0
    },
    {
      title: "CALL NOT PICKED TEXT SENT",
      dataIndex: "CALL NOT PICKED TEXT SENT",
      key: "CALL NOT PICKED TEXT SENT",
      render: (text) => <a>{text ? text : 0}</a>,
      sorter: (a, b) => (a["CALL NOT PICKED TEXT SENT"] || 0) - (b["CALL NOT PICKED TEXT SENT"] || 0),
    },
    {
      title: "HOT",
      dataIndex: "HOT",
      key: "HOT",
      render: (text) => <a>{text ? text : 0}</a>,
      sorter: (a, b) => (a.HOT || 0) - (b.HOT || 0),
    },
    {
      title: "AGE NOT VALID",
      dataIndex: "AGE NOT VALID",
      key: "AGE NOT VALID",
      render: (text) => <a>{text ? text : 0}</a>,
      sorter: (a, b) => (a["AGE NOT VALID"] || 0) - (b["AGE NOT VALID"] || 0),
    },
    {
      title: "INVALID NUMBER",
      dataIndex: "INVALID NUMBER",
      key: "INVALID NUMBER",
      render: (text) => <a>{text ? text : 0}</a>,
      sorter: (a, b) => (a["INVALID NUMBER"] || 0) - (b["INVALID NUMBER"] || 0),
    },
    {
      title: "JUNK",
      dataIndex: "JUNK",
      key: "JUNK",
      render: (text) => <a>{text ? text : 0}</a>,
      sorter: (a, b) => (a.JUNK || 0) - (b.JUNK || 0),
    },
    {
      title: "CONVERTED",
      dataIndex: "CONVERTED",
      key: "CONVERTED",
      render: (text) => <a>{text ? text : 0}</a>,
      sorter: (a, b) => (a.CONVERTED || 0) - (b.CONVERTED || 0),
    },
    {
      title: "DIRECT",
      dataIndex: "DIRECT",
      key: "DIRECT",
      render: (text) => <a>{text ? text : 0}</a>,
      sorter: (a, b) => (a.DIRECT || 0) - (b.DIRECT || 0),
    },
    {
      title: "NOT INTERESTED",
      dataIndex: "NOT INTERESTED",
      key: "NOT INTERESTED",
      render: (text) => <a>{text ? text : 0}</a>,
      sorter: (a, b) => (a["NOT INTERESTED"] || 0) - (b["NOT INTERESTED"] || 0),
    },
  ];

  const handleUtmChange = (value) => {
    setError(false)
    setUtm(value);
  };

  return (
    <div className="catalys-utm-analytics">
      <h1 className="text-3xl" style={{ margin: "10px" }}>
        Analytics
      </h1>
      <p style={{ marginBottom: "20px" }}>
        Last updated: {new Date().toLocaleDateString()}
      </p>

      {error && <p style={{ color: "red",fontWeight:"bold" }}>{error}</p>}

      <Row gutter={16}>
        <Col span={4}>
          <Select
            placeholder="Select UTM Tag"
            style={{ width: "100%" }}
            onChange={(value) => setUtm(value)}
          >
           <Select.Option value={undefined}>None</Select.Option>
            <Select.Option value="utm_source">utm_source</Select.Option>
            <Select.Option value="utm_content">utm_content</Select.Option>
            <Select.Option value="utm_campaign">utm_campaign</Select.Option>
            <Select.Option value="utm_medium">utm_medium</Select.Option>
            <Select.Option value="utm_placement">utm_placement</Select.Option>
          </Select> <br />
          <Button style={{marginTop:"10px"}} type="primary" onClick={fetchData}>
            Fetch Data
          </Button>
        </Col>
        <Col span={8}>
          <DateRangePicker
            ranges={dateState}
            onChange={(item) => setDateState([item.selection])}
          />
        </Col>
      </Row>

      {loading ? (
        <Loader />
      ) : data && data?.length > 0 ?(
        <Table
            columns={columns}
            dataSource={filteredData || data}
            pagination={15}
            rowClassName="table-row"
            className="catalys-table"
          />
      ):""}
    </div>
  );
};

export default CatalysUtmAnalytics;
